import React from "react"
import { Row, Icon, Tag, Tooltip } from "antd"
import { injectIntl } from "gatsby-plugin-intl"

class PermissionsView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  getIconToPermissionValue(permission) {
    if (permission.value) {
      return <Icon style={{ color: "green" }} type="check" />
    } else if (permission.value === false) {
      return <Icon style={{ color: "red" }} type="close" />
    }
    return null
  }

  render() {
    return (
      <div>
        <Row className="kpi-content" style={{ padding: "0px" }}>
          <table>
            <thead className="kpi-title" style={{ background: "#F2F2F2" }}>
              <tr style={{ textAlign: "center" }}>
                <th style={{ textAlign: "left", padding: "15px 50px" }}>
                  {this.props.intl.formatMessage({
                    id: "page-role-component-table-operation",
                  })}
                </th>
                <th>
                  {this.props.intl.formatMessage({
                    id: "page-role-component-table-permission-read",
                  })}
                </th>
                <th>
                  {this.props.intl.formatMessage({
                    id: "page-role-component-table-permission-write",
                  })}
                </th>
                <th>
                  {this.props.intl.formatMessage({
                    id: "page-role-component-table-permission-delete",
                  })}
                </th>
                <th>
                  {this.props.intl.formatMessage({
                    id: "page-role-component-table-permission-activate",
                  })}
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.permissionsModel
                .filter(item => item.type === "operation")
                .map((permission, index) => (
                  <tr
                    key={`tr-${index}`}
                    style={{ textAlign: "center", height: 35 }}
                  >
                    <td style={{ textAlign: "left", paddingLeft: 50 }}>
                      {this.props.intl.formatMessage({
                        id: permission.title,
                      })}
                    </td>
                    {permission.permissions.map(item => (
                      <td key={item.type}>
                        {this.getIconToPermissionValue(item)}
                      </td>
                    ))}
                    {permission.hint && (
                      <Tooltip
                        title={this.props.intl.formatMessage({
                          id: permission.hint,
                        })}
                      >
                        &nbsp;
                        <Icon type="info-circle" size="large" />
                      </Tooltip>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </Row>
        <Row className="kpi-content">
          <table>
            <thead className="kpi-title">
              <tr>
                <th style={{ textAlign: "left" }}>
                  {this.props.intl.formatMessage({
                    id: "page-role-component-table-consume",
                  })}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ height: 35 }}>
                {this.props.permissionsModel
                  .find(item => item.type === "consume")
                  .permissions.map((permission, index) => (
                    <td
                      style={{ textAlign: "left", paddingLeft: 10 }}
                      key={`tr-consume-${index}`}
                    >
                      <span style={{ marginRight: 5 }}>
                        {this.getIconToPermissionValue(permission)}
                      </span>
                      {this.props.intl.formatMessage({
                        id: permission.title,
                      })}
                      {permission.hint && (
                        <Tooltip
                          title={this.props.intl.formatMessage({
                            id: permission.hint,
                          })}
                        >
                          &nbsp;
                          <Icon type="info-circle" size="large" />
                        </Tooltip>
                      )}
                    </td>
                  ))}
              </tr>
            </tbody>
          </table>
        </Row>
        <Row className="kpi-content">
          <div>
            <div className="kpi-title">
              {this.props.intl.formatMessage({ id: "menu-ingestions" })}
            </div>
            <div style={{ marginTop: 10, paddingLeft: 10 }}>
              {this.props.role.ingests && this.props.role.ingests.length > 0 ? (
                this.props.role.ingests.map(ingest => (
                  <Tag
                    key={ingest}
                    style={{ fontSize: 13, marginBottom: 10 }}
                    type="primary"
                  >
                    {ingest === "*" ? "Todas las ingestas" : ingest}
                  </Tag>
                ))
              ) : (
                <div className="no-item-placeholder">
                  {this.props.intl.formatMessage({
                    id: "page-role-component-no-ingests-added",
                  })}
                </div>
              )}
            </div>
          </div>
        </Row>
      </div>
    )
  }
}
export default injectIntl(PermissionsView)
