import React from "react"
import { Button, Col, message, Modal, Row, Spin } from "antd"
import CustomBreadcrumb from "../../components/commonBreadcrumb"
import PermissionsView from "../../components/permissions/permissionsView"
import Template from "../../layouts/base"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import withAuth from "../../components/withAuthHoc"
import authFetch from "../../services/network"
import { callbackRolesErrorMessages } from "../../services/roles/rolesRequestErrorMessages"
import {
  checkPermissions,
  getPermissionsValue,
} from "../../services/auth/permissions/permissions"

const { confirm } = Modal

class RoleDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      roleName: props.location && props.location.pathname.split("/").pop(),
      role: null,
      permissionsModel: null,
      processing: false,
    }
    this.goToEditRole = this.goToEditRole.bind(this)
    this.deleteRole = this.deleteRole.bind(this)
    this._callbackGetRole = this._callbackGetRole.bind(this)
    this._callbackRemoveRole = this._callbackRemoveRole.bind(this)
    this._callbackRemoveRoleError = this._callbackRemoveRoleError.bind(this)
    this.onDeleteRoleOk = this.onDeleteRoleOk.bind(this)
  }

  componentDidMount() {
    authFetch(`${process.env.GATSBY_CONF_API_URL}/roles/${this.state.roleName}`)
      .then(response => {
        response.json().then(role => {
          if (200 !== response.status) {
            callbackRolesErrorMessages(response, "GET").then(text => {
              message.error(this.props.intl.formatMessage({ id: text }))
            })
          } else {
            this._callbackGetRole(role)
          }
        })
      })
      .catch(reason => {
        callbackRolesErrorMessages(null, "GET").then(text => {
          message.error(this.props.intl.formatMessage({ id: text }))
        })
        throw new Error(`[ERROR] Retrieving role detail: ${reason}`)
      })
  }

  _callbackGetRole(role) {
    const permissionsFromService = getPermissionsValue(role.permissions)
    this.setState({
      role,
      permissionsModel: permissionsFromService,
    })
  }

  goToEditRole() {
    navigate(`/role/edit/${this.state.roleName}`)
  }

  onDeleteRoleOk() {
    this.setState({ processing: true })
    const _callbackRemoveRole = this._callbackRemoveRole
    const _callbackRemoveRoleError = this._callbackRemoveRoleError
    const _role = this.state.role
    authFetch(`${process.env.GATSBY_CONF_API_URL}/roles/${_role.name}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(response => _callbackRemoveRole(response, _role))
      .catch(error => _callbackRemoveRoleError(error))
  }

  deleteRole() {
    const _thatIntl = this.props.intl
    const _role = this.state.role
    const onDeleteRoleOk = this.onDeleteRoleOk
    confirm({
      title: _thatIntl.formatMessage({ id: "modal-are-you-sure" }),
      content: _thatIntl.formatMessage(
        { id: "page-role-delete-role-modal-text" },
        { name: _role.name }
      ),
      okText: _thatIntl.formatMessage({ id: "modal-ok" }),
      okType: "danger",
      cancelText: _thatIntl.formatMessage({ id: "modal-ko" }),
      onOk: onDeleteRoleOk,
      onCancel() {
        message.info(_thatIntl.formatMessage({ id: "modal-delete-cancel" }))
      },
    })
  }

  _callbackRemoveRole(response) {
    this.setState({ processing: false })
    if (response.status !== 204) {
      callbackRolesErrorMessages(response, "DELETE").then(text => {
        message.error(this.props.intl.formatMessage({ id: text }))
      })
    } else {
      message.success(
        this.props.intl.formatMessage({ id: "page-role-delete-ok-text" })
      )
      navigate(`/role`)
    }
  }

  _callbackRemoveRoleError() {
    this.setState({ processing: false })
    callbackRolesErrorMessages(null, "DELETE").then(text => {
      message.error(this.props.intl.formatMessage({ id: text }))
    })
  }

  render() {
    return (
      <Template selected={["role", "role-detail"]}>
        <CustomBreadcrumb
          crumbs={[
            {
              label: this.props.intl.formatMessage({
                id: "menu-role-managment",
              }),
              link: "/role",
            },
            this.props.intl.formatMessage({ id: "menu-role-detail" }),
          ]}
        />
        <Row style={{ display: "flex", alignItems: "center" }}>
          <Col span={18} className="knolar-intro">
            {this.props.intl.formatMessage({ id: "menu-role-detail" })}
          </Col>
          <Col span={6} style={{ display: "flex", justifyContent: "flex-end" }}>
            {checkPermissions(["role:delete"]) ? (
              <Button
                className="knolar-button back"
                type="secondary"
                style={{ marginRight: 15 }}
                onClick={this.deleteRole}
                loading={this.state.processing}
              >
                {this.props.intl.formatMessage({ id: "menu-role-delete" })}
              </Button>
            ) : null}
            {checkPermissions(["role:write"]) ? (
              <Button
                className="knolar-button"
                type="primary"
                onClick={this.goToEditRole}
              >
                {this.props.intl.formatMessage({ id: "menu-role-update" })}
              </Button>
            ) : null}
          </Col>
        </Row>
        {this.state.role ? (
          <div>
            <div className="kpi-content" style={{ paddingLeft: "40px" }}>
              <Row>
                <h3>{this.state.role.name}</h3>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <div>{this.state.role.description}</div>
              </Row>
            </div>
            <PermissionsView
              permissionsModel={this.state.permissionsModel}
              role={this.state.role}
            />
          </div>
        ) : (
          <div className="kpi-content">
            <Row
              style={{
                height: "200px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spin size="large" />
            </Row>
          </div>
        )}
      </Template>
    )
  }
}

export default injectIntl(withAuth(RoleDetail))
